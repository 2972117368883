import React, { useEffect } from 'react'
import { Avatar, Button, Col, Dropdown, Input, Menu, Popover, Row, Space, Typography } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Lang } from '../shared/svgs/language.svg'
import { ReactComponent as NlLang } from '../shared/svgs/netherlands.svg'
import { ReactComponent as UkLang } from '../shared/svgs/united-kingdom.svg'
import { authenticationService } from '../shared/services'
import moment from 'moment'
import { useState } from 'react'

const { Search } = Input

const LANGUAGE_KEY = 'RQ_USER_LANG'

const TopBar = (props) => {
  const [currentUser, setCurrentUser] = useState(null)
  const { t, i18n } = useTranslation('topbar')

  const setLanguage = (lang, refresh = false) => {
    i18n.changeLanguage(lang)
    moment.locale(lang)
    localStorage.setItem(LANGUAGE_KEY, lang)
  }

  const onLogout = (e) => {
    authenticationService.logout()
    window.location.reload()
  }

  const currentUserAvatar = () => {
    return currentUser ? `${currentUser.firstName.charAt(0).toUpperCase()}${currentUser.lastName.charAt(0).toUpperCase()}` : ''
  }

  useEffect(() => {
    const storedLang = localStorage.getItem(LANGUAGE_KEY)
    if (storedLang) setLanguage(storedLang)

    setCurrentUser(authenticationService.currentUserValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const languagesMenu = (
    <Menu onClick={(e) => setLanguage(e.key, true)}>
      <Menu.Item key="nl" icon={<NlLang style={{ width: 16, height: 16, marginRight: 10 }} />}>
        {t('dutch')}
      </Menu.Item>
      <Menu.Item key="en" icon={<UkLang style={{ width: 16, height: 16, marginRight: 10 }} />}>
        {t('english')}
      </Menu.Item>
    </Menu>
  )

  const userPopover = (
    <Row style={{ width: 150 }} align="middle" justify="center">
      <Col span={24} style={{ textAlign: 'center', marginBottom: 10 }}>
        <Avatar size={64} className="app-header-avatar">
          {currentUserAvatar()}
        </Avatar>
      </Col>
      <Col span={24}>
        {currentUser && (
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            {currentUser.firstName} {currentUser.lastName}
          </Typography.Title>
        )}
      </Col>
      {/* <Col span={24} style={{ marginTop: 15 }}>
        <Button type="text" style={{ width: '100%', textAlign: 'left' }} icon={<UserOutlined />}>
          Profile
        </Button>
      </Col> */}
      <Col span={24}>
        <Button type="text" style={{ width: '100%', textAlign: 'left' }} onClick={onLogout} icon={<PoweroffOutlined />}>
          {t('logout')}
        </Button>
      </Col>
    </Row>
  )

  return (
    <Space align="center" size="middle">
      <div>
        <Search allowClear placeholder="Search..." className="search-input" />
      </div>
      <div>
        <Dropdown overlay={languagesMenu}>
          <Button shape="circle" icon={<Lang style={{ width: 16, height: 16 }} />} className="button-as-text" />
        </Dropdown>
      </div>
      {/* <div>
        <Badge count={5}>
          <Button shape="circle" icon={<BellFilled />} className="button-as-text" />
        </Badge>
      </div> */}
      <div>
        <Popover content={userPopover} trigger="click" placement="bottomRight">
          <Button shape="circle" style={{ padding: 0 }} className="button-as-text">
            <Avatar style={{ width: '100%', height: '100%' }} className="app-header-avatar">
              {currentUserAvatar()}
            </Avatar>
          </Button>
        </Popover>
      </div>
    </Space>
  )
}

export default TopBar
