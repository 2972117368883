import React, { useState } from 'react'
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import '../shared/less/login.less'
import logo from '../shared/images/hydroscope_logo.png'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { authenticationService } from '../shared/services'
import { useHistory } from 'react-router'

const Login = (props) => {
  const [form] = Form.useForm()
  let history = useHistory()
  const { t } = useTranslation('login')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onLogin = ({ email, password }) => {
    setLoading(true)
    authenticationService
      .login(email, password)
      .then(() => {
        history.push('/')
      })
      .catch((error) => {
        setLoading(false)
        setError(true)
        console.error(error)
      })
  }

  return (
    <div className="login-wrapper">
      <Row justify="center" align="middle" className="login">
        <Col xxl={4} xl={6} lg={8}>
          <Layout>
            <img src={logo} className="app-logo" alt="ReportingQ" style={{ maxWidth: 500 }} />
            {/* <Typography.Title level={3}>
              Reporting<span>Q</span>
            </Typography.Title> */}
            <Typography.Paragraph>{t('please_login')}</Typography.Paragraph>
            {error && <Alert message={t('incorrect_login')} type="error" showIcon />}
            <Form form={form} layout="vertical" initialValues={{}} onFinish={onLogin}>
              <Form.Item name="email" rules={[{ required: true, message: t('required_email') }]}>
                <Input placeholder={t('email')} size="large" prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: t('required_password') }]}>
                <Input.Password placeholder={t('password')} size="large" prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>{t('remember')}</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" size="large" block htmlType="submit" loading={loading}>
                  {loading ? t('loading') : t('login')}
                </Button>
              </Form.Item>
            </Form>
            <Button size="small" type="text" block>
              {t('forgot_password')}
            </Button>
          </Layout>
        </Col>
      </Row>
    </div>
  )
}
export default Login
