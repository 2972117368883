import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Page from '../components/Page'
import { Select, Col, Row, Form, Input, DatePicker, Button, Tag, Descriptions, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../shared/components'
import { queryOperator } from '../shared/helpers'

let autoUpdateInterval = 1000 * 1000 * 5 // 5 minutes

const Alarms = (props) => {
  const [filters, setFilters] = useState({})
  const [dates, setDates] = useState([])
  const [lastUpdated, setLastUpdated] = useState(new Date())
  const [autoUpdate, setAutoUpdate] = useState(0)
  const [form] = Form.useForm()
  const { t } = useTranslation(['global', 'alarms'])

  const refresh = () => {
    setFilters({ ...filters })
  }

  const onFilterFinish = (values) => {
    const newFilters = {}

    if (values.sensor) newFilters['sensor.name'] = { value: values.sensor, operator: queryOperator.contains }
    if (dates && dates.length > 0) {
      const dateStrings = dates.map((d) => moment(d).hours(0).minutes(0).seconds(0).toJSON()).join('|')
      newFilters.betweenDates = { value: dateStrings, operator: queryOperator.contains }
    }
    if (values.level) newFilters.level = { value: values.level, operator: queryOperator.equals }

    setFilters(newFilters)
  }

  const formatDateTime = (value) => {
    return moment.utc(value).local().format('llll')
  }

  const columns = [
    {
      title: t('alarms:date_time'),
      dataIndex: 'timestamp',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: (text, _) => <span>{formatDateTime(text)}</span>
    },
    {
      title: t('alarms:level'),
      dataIndex: 'level',
      sorter: true,
      render: (text, _) => {
        return (
          <Tag color={text === 'Error' ? 'red' : text === 'Info' ? 'blue' : text === 'Warning' ? 'orange' : ''}>
            {t(`alarms:level_${text.toLowerCase()}`)}
          </Tag>
        )
      }
    },
    {
      title: t('alarms:sensor'),
      sorter: true,
      render: (_, record) => <span>{record.sensor?.name}</span>
    },
    {
      title: t('alarms:description'),
      render: (_, record) => <span>{t(`alarms:key_${record.key.toLowerCase()}`)}</span>
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      setFilters({ ...filters })
      setAutoUpdate(autoUpdate + 1)
    }, autoUpdateInterval)
  }, [autoUpdate])

  useEffect(() => {
    setAutoUpdate(1)
  }, [])

  const alarmDetails = (row) => {
    return (
      <Descriptions size="small" labelStyle={{ fontWeight: 'bold', fontSize: '0.8em' }} contentStyle={{ fontSize: '0.8em' }}>
        <Descriptions.Item label={t('alarms:date_time')}>{formatDateTime(row.timestamp)}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:sensor')}>{row.sensor?.name}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:description')}>{t(`alarms:key_${row.key.toLowerCase()}`)}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:customer')}>{row.props?.customer}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:location')}>{row.props?.location}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:site')}>{row.props?.site}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:group')}>{row.props?.group}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:room')}>{row.props?.room}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:component')}>{row.props?.component}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:value')}>{row.props?.value}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:max')}>{row.props?.maxValue}</Descriptions.Item>
        <Descriptions.Item label={t('alarms:min')}>{row.props?.minValue}</Descriptions.Item>
      </Descriptions>
    )
  }

  return (
    <Page
      title="alarms"
      extra={[
        <Typography.Text style={{ fontSize: '0.8em' }}>
          {t('last_updated', { timestamp: formatDateTime(lastUpdated) })}
        </Typography.Text>,
        <Button key={1} onClick={() => refresh()}>
          {t('refresh')}
        </Button>
      ]}
    >
      <Row gutter={30}>
        <Col span={18} xxl={19}>
          <DataTable
            columns={columns}
            filters={filters}
            expandable={alarmDetails}
            sorter={{ field: 'timestamp', order: 'descend' }}
            onUpdate={() => setLastUpdated(new Date())}
            endpoint="alarms"
          />
        </Col>
        <Col span={6} xxl={5}>
          <Form form={form} layout="vertical" initialValues={filters} onFinish={onFilterFinish}>
            <Form.Item label={t('alarms:sensor')} name="sensor">
              <Input allowClear placeholder={t('alarms:search_by_sensor')} />
            </Form.Item>
            <Form.Item label={t('alarms:date')} name="dates">
              <DatePicker.RangePicker initialValues={dates} onChange={(s, _) => setDates(s)} />
            </Form.Item>
            <Form.Item label={t('alarms:level')} name="level">
              <Select allowClear>
                <Select.Option value="info">{t('alarms:level_info')}</Select.Option>
                <Select.Option value="warning">{t('alarms:level_warning')}</Select.Option>
                <Select.Option value="error">{t('alarms:level_error')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('refresh')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}

export default Alarms
