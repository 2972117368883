import React from 'react'
import { Layout, Typography } from 'antd'
import SideBar from './SideBar'
import TopBar from './TopBar'
import { HeartFilled } from '@ant-design/icons'

const { Header, Footer, Sider, Content } = Layout
const { Text, Link } = Typography
const currentDate = new Date()

const MainLayout = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <SideBar />
      </Sider>
      <Layout>
        <Header className="app-header">
          <TopBar />
        </Header>
        <Content>{props.children}</Content>
        <Footer style={{ fontSize: '0.9em', textAlign: 'center' }}>
          <Text type="secondary">ReportingQ &copy; {currentDate.getFullYear()}</Text>
          <Text type="secondary" style={{ display: 'block', fontSize: '0.9em' }}>
            Developed with&nbsp;
            <Text type="danger">
              <HeartFilled />
            </Text>
            &nbsp;by <Link href="https://www.technoberg.nl">Technoberg</Link>.
          </Text>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout
