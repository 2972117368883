import React, { useState } from 'react'
import { Form, Select, Input, Button, Modal, Row, Col, Spin, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Page from '../components/Page'
import { DataTable, DataTableActions, RemoteSelect } from '../shared/components'
import { queryOperator, Role } from '../shared/helpers'
import UserDetails from '../components/UserDetails'

const Users = (props) => {
  const [filters, setFilters] = useState({})
  const [customParams, setCustomParams] = useState(null)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState(null)
  const [form] = Form.useForm()
  const { t } = useTranslation(['global', 'users'])

  const getRoleTagColor = (role) => {
    switch (role) {
      case Role.SUPER_ADMIN:
        return 'purple'
      case Role.ADMIN:
        return 'cyan'
      default:
        return null
    }
  }

  const refresh = () => {
    setFilters({ ...filters })
    setDetails(null)
  }

  const onFilterFinish = (values) => {
    const newFilters = {}

    if (values.name) {
      newFilters['firstName|lastName'] = { value: values.name, operator: queryOperator.contains }
    }
    if (values.email) newFilters.email = { value: values.email, operator: queryOperator.contains }
    if (values.isEnabled) newFilters.isEnabled = { value: values.isEnabled, operator: queryOperator.equals }

    if (values.roles) {
      setCustomParams({ roles: values.roles })
    } else {
      setCustomParams(null)
    }

    setFilters(newFilters)
  }

  const onDelete = (item) => {
    setLoading(true)
    axios
      .delete(`/api/users/${item.id}`)
      .then(() => {
        refresh()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const columns = [
    {
      title: t('users:name'),
      dataIndex: 'firstName',
      sorter: true,
      render: (_, record) => <span>{`${record.firstName} ${record.lastName}`}</span>
    },
    {
      title: t('users:role'),
      dataIndex: 'roles',
      render: (_, record) => <Tag color={getRoleTagColor(record.roles[0])}>{record.roles[0]}</Tag>
    },
    {
      title: t('users:email'),
      dataIndex: 'email'
    },
    {
      title: t('users:customer'),
      dataIndex: 'customerId',
      render: (_, record) => <span>{record.customer?.name}</span>
    },
    {
      title: t('users:isEnabled'),
      dataIndex: 'isEnabled',
      render: (_, record) => (
        <Tag color={record.isEnabled ? 'green' : 'red'}>{record.isEnabled ? t('users:enabled') : t('users:disabled')}</Tag>
      )
    },
    {
      title: '',
      key: 'actions',
      width: '15%',
      render: (_, record) => <DataTableActions record={record} onEdit={setDetails} onDelete={onDelete} />
    }
  ]

  return (
    <Page
      title="users"
      extra={[
        <Button key="1" onClick={() => setDetails({})}>
          {t('users:new_user')}
        </Button>
      ]}
    >
      <Modal
        destroyOnClose
        closable={false}
        footer={null}
        centered
        title={details?.name || t('users:new_user')}
        visible={!!details}
      >
        <UserDetails user={details} onSuccessfullySaved={refresh} onCancel={() => setDetails(null)} />
      </Modal>

      <Row gutter={30}>
        <Col span={18} xxl={20}>
          <Spin spinning={loading}>
            <DataTable columns={columns} filters={filters} customParams={customParams} endpoint="users" />
          </Spin>
        </Col>
        <Col span={6} xxl={4}>
          <Form form={form} layout="vertical" initialValues={filters} onFinish={onFilterFinish}>
            <Form.Item label={t('users:name')} name="name">
              <Input allowClear placeholder={t('users:search_by_name')} />
            </Form.Item>
            <Form.Item label={t('users:email')} name="email">
              <Input allowClear placeholder={t('users:search_by_email')} />
            </Form.Item>
            <Form.Item label={t('users:role')} name="roles">
              <Select allowClear>
                <Select.Option value={Role.SUPER_ADMIN}>{Role.SUPER_ADMIN}</Select.Option>
                <Select.Option value={Role.ADMIN}>{Role.ADMIN}</Select.Option>
                <Select.Option value={Role.USER}>{Role.USER}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={t('users:isEnabled')} name="isEnabled">
              <Select allowClear>
                <Select.Option value="true">{t('users:enabled')}</Select.Option>
                <Select.Option value="false">{t('users:disabled')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('refresh')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}
export default Users
