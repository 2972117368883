import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Col, Form, Input, Row, Spin, Button, Space, Select, Switch } from 'antd'
import { RemoteSelect } from '../shared/components'
import { Role } from '../shared/helpers'

const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/

const UserDetails = (props) => {
  const [user, setUser] = useState({})
  const [isCreating, setIsCreating] = useState(true)
  const [customer, setCustomer] = useState(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['global', 'users'])
  const [form] = Form.useForm()

  const onSave = (values) => {
    setLoading(true)

    const data = Object.assign(user, values)
    if (!Array.isArray(data.roles)) data.roles = [data.roles]

    const request = !data.id ? axios.post('/api/users', data) : axios.put(`/api/users/${data.id}`, data)

    request
      .then(() => props.onSuccessfullySaved())
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }

  const onSaveFailed = (error) => {
    console.log(error)
  }

  const onReset = () => {
    form.resetFields()
    setUser({})
    if (props.onCancel) props.onCancel()
  }

  const passwordConfirmValidator = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(t('password_confirm_error')))
    }
  })

  useEffect(() => {
    const newUser = { ...props.user }
    setUser(newUser)
    form.setFieldsValue(newUser)
    setIsCreating(!newUser?.id)
  }, [props.user])

  useEffect(() => {
    if (customer && customer.id) {
      setUser({ ...user, customerId: customer.id })
      form.setFieldsValue({ ...form.getFieldsValue(), customerId: customer.id })
    }
  }, [customer, form])

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" initialValues={user} onFinish={onSave} onFinishFailed={onSaveFailed}>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item label={t('users:first_name')} name="firstName" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('users:last_name')} name="lastName" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('users:email')} name="email" rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          {isCreating && (
            <>
              <Col span={24}>
                <Form.Item
                  label={t('users:password')}
                  name="password"
                  rules={[{ required: true }, { pattern: passwordPattern, message: t('error_invalid_password') }]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('users:password_confirm')}
                  name="passwordConfirm"
                  dependencies={['password']}
                  autoComplete="off"
                  rules={[{ required: true }, passwordConfirmValidator]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item label={t('users:role')} name="roles" rules={[{ required: true }]}>
              <Select>
                <Select.Option value={Role.SUPER_ADMIN}>{Role.SUPER_ADMIN}</Select.Option>
                <Select.Option value={Role.ADMIN}>{Role.ADMIN}</Select.Option>
                <Select.Option value={Role.USER}>{Role.USER}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('users:phone')} name="phoneNumber">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('users:customer')}>
              <RemoteSelect
                endpoint="customers"
                fieldValue={user.customerId}
                searchProperty="name"
                onChange={(v) => setCustomer(v)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('users:isEnabled')}>
              <Switch
                checked={user.isEnabled}
                checkedChildren={t('users:enabled')}
                unCheckedChildren={t('users:disabled')}
                onChange={() => setUser({ ...user, isEnabled: !user.isEnabled })}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Space align="end">
                <Button type="primary" htmlType="submit">
                  {t('save')}
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  {t('cancel')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

export default UserDetails
