import { Button, Col, Form, Input, Modal, Row, Select, Spin, Tag } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../components/Page'
import SensorDetails from '../components/SensorDetails'
import { DataTable, DataTableActions } from '../shared/components'
import { queryOperator, SensorStatus, SensorType } from '../shared/helpers'

const Sensors = (props) => {
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState(null)
  const [form] = Form.useForm()
  const { t } = useTranslation(['global', 'sensors'])

  const columns = [
    {
      title: t('sensors:name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('sensors:status'),
      dataIndex: 'status',
      sorter: true,
      render: (_, record) => {
        let color = ''
        let status = t('sensors:status_disabled')
        if (record.status === SensorStatus.ACTIVE) {
          color = 'green'
          status = t('sensors:status_active')
        }
        return <Tag color={color}>{status}</Tag>
      }
    },
    {
      title: t('sensors:customer'),
      dataIndex: 'customer.name',
      width: '15%',
      sorter: true,
      render: (_, record) => <span>{record.customer.name}</span>
    },
    {
      title: t('sensors:location'),
      dataIndex: 'location.name',
      width: '15%',
      sorter: true,
      render: (_, record) => <span>{record.location?.name}</span>
    },
    {
      title: t('sensors:site'),
      dataIndex: 'site.name',
      width: '15%',
      sorter: true,
      render: (_, record) => <span>{record.site?.name}</span>
    },
    {
      title: t('sensors:group'),
      dataIndex: 'group.name',
      width: '15%',
      sorter: true,
      render: (_, record) => <span>{record.group?.name}</span>
    },
    {
      title: t('sensors:notes'),
      dataIndex: 'notes',
      sorter: true
    },
    {
      title: '',
      key: 'actions',
      width: '15%',
      render: (text, record) => <DataTableActions record={record} onEdit={setDetails} onDelete={onDelete} />
    }
  ]

  const refresh = () => {
    setFilters({ ...filters })
    setDetails(null)
  }

  const onDelete = (item) => {
    setLoading(true)
    axios
      .delete(`/api/sensors/${item.id}`)
      .then(() => {
        refresh()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onFilterFinish = (values) => {
    const newFilters = {}

    if (values.name) newFilters.name = { value: values.name, operator: queryOperator.contains }
    if (values.status) newFilters.status = { value: values.status, operator: queryOperator.equals }

    setFilters(newFilters)
  }

  return (
    <Page
      title="sensors"
      extra={[
        <Button key="1" onClick={() => setDetails({ type: SensorType.CO2 })}>
          {t('sensors:new_sensor')}
        </Button>
      ]}
    >
      <Modal
        destroyOnClose
        closable={false}
        footer={null}
        centered
        title={details?.name || t('sensors:new_sensor')}
        visible={!!details}
        width={1200}
      >
        <SensorDetails sensor={details} onSuccessfullySaved={refresh} onCancel={() => setDetails(null)} />
      </Modal>

      <Row gutter={30}>
        <Col span={18} xxl={20}>
          <Spin spinning={loading}>
            <DataTable columns={columns} filters={filters} endpoint="sensors" size="small" />
          </Spin>
        </Col>
        <Col span={6} xxl={4}>
          <Form form={form} layout="vertical" initialValues={filters} onFinish={onFilterFinish}>
            <Form.Item label={t('sensors:name')} name="name">
              <Input allowClear placeholder={t('sensors:search_by_name')} />
            </Form.Item>
            <Form.Item label={t('sensors:status')} name="status">
              <Select allowClear>
                <Select.Option value="active">{t('sensors:status_active')}</Select.Option>
                <Select.Option value="disabled">{t('sensors:status_disabled')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('refresh')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}

export default Sensors
