import axios from 'axios'
import { BehaviorSubject } from 'rxjs'

const CURRENT_USER_KEY = 'CURRENT_USER'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(CURRENT_USER_KEY)))

export const authenticationService = {
  login,
  logout,
  hasRoles,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value
  }
}

function login(email, password) {
  return axios.post('/api/auth/token', { email, password }, { headers: { 'Content-Type': 'application/json' } }).then((res) => {
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(res.data.token))
    currentUserSubject.next(res.data.token)
  })
}

function logout() {
  // remove user from local storage and refresh page to log user out
  localStorage.removeItem(CURRENT_USER_KEY)
  currentUserSubject.next(null)
  window.location.reload()
}

function hasRoles(roles) {
  return currentUserSubject.value?.roles && currentUserSubject.value.roles.some((x) => roles.includes(x))
}
