import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Button, Space, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import AlarmRulesDetails from './AlarmRulesDetails'

const GroupDetails = (props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation(['global', 'groups'])
  const [group, setGroup] = useState({})
  const [rules, setRules] = useState([])
  const [loading, setLoading] = useState(false)

  const onSave = (values) => {
    setLoading(true)

    const data = Object.assign(group, values)
    const request = !data.id || data.id === 0 ? axios.post('/api/groups', data) : axios.put(`/api/groups/${group.id}`, data)

    request
      .then(() => {
        props.onSuccessfullySaved()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSaveFailed = (error) => {
    console.log('🚀 ~ file: CustomerDetails.js ~ line 12 ~ CustomerDetails ~ error', error)
  }

  const onReset = () => {
    form.resetFields()
    setGroup({})
    setRules([])
    if (props.onCancel) props.onCancel()
  }

  useEffect(() => {
    const newGroup = { ...props.group }
    setGroup(newGroup)
    setRules(newGroup.alarmRules || [])
    form.setFieldsValue(newGroup)
  }, [props.group, form])

  return (
    <div>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={group} onFinish={onSave} onFinishFailed={onSaveFailed}>
          <Form.Item label={t('groups:name')} name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <div style={{ marginBottom: 25 }}>
            <AlarmRulesDetails
              rules={rules}
              endpoint={group.id ? `groups/${group.id}` : null}
              onChange={(r) => setGroup({ ...group, alarmRules: r })}
            />
          </div>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space align="end">
              <Button type="primary" htmlType="submit">
                {t('save')}
              </Button>
              <Button htmlType="button" onClick={onReset}>
                {t('cancel')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  )
}
export default GroupDetails
