import { authHeader } from './auth-header'

export const authRequestInterceptor = {
  onSuccess: (req) => {
    req.headers = authHeader()
    return req
  },
  onFailed: (error) => {
    return Promise.reject(error)
  }
}
