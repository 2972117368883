import { authenticationService } from '../services'

export const authHeader = () => {
  const user = authenticationService.currentUserValue

  if (user && user.accessToken) {
    return { Authorization: `Bearer ${user.accessToken}` }
  }

  return {}
}
