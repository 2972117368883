import React, { useState } from 'react'
import axios from 'axios'
import { Badge, Button, Modal, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { DataTableActions } from '../shared/components'
import { sortByName } from '../shared/helpers'
import { PlusCircleOutlined } from '@ant-design/icons'
import SiteDetails from './SiteDetails'
import GroupsTable from './GroupsTable'

const SitesTable = (props) => {
  const { t } = useTranslation(['global', 'sites'])
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(false)

  const refresh = () => {
    props.onRefresh()
    setDetails(null)
  }

  const onDelete = (item) => {
    setLoading(true)
    axios
      .delete(`/api/sites/${item.id}`)
      .then(() => {
        refresh()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const newSiteButton = (
    <Tooltip title={t('sites:new_site')}>
      <Button
        type="text"
        size="small"
        shape="circle"
        icon={<PlusCircleOutlined />}
        onClick={() => setDetails({ locationId: props.locationId })}
      />
    </Tooltip>
  )

  const columns = [
    {
      title: t('sites:name'),
      dataIndex: 'name',
      sorter: (a, b) => sortByName(a.name, b.name)
    },
    {
      title: t('sites:groups'),
      key: 'groups',
      align: 'center',
      width: '15%',
      render: (text, record) => <Badge showZero className="site-badge-count-4" count={record.groups.length} />
    },
    {
      title: newSiteButton,
      key: 'actions',
      align: 'right',
      width: '15%',
      render: (text, record) => <DataTableActions record={record} onEdit={setDetails} onDelete={onDelete} />
    }
  ]

  const getGroupsTable = (row) => {
    return <GroupsTable groups={row.groups} siteId={row.id} onRefresh={refresh} />
  }

  return (
    <div className="sites-table">
      <Modal
        closable={false}
        destroyOnClose
        footer={null}
        centered
        title={details?.name || t('sites:new_site')}
        visible={!!details}
      >
        <SiteDetails site={details} onSuccessfullySaved={refresh} onCancel={() => setDetails(null)} />
      </Modal>
      <Table
        columns={columns}
        dataSource={props.sites}
        loading={loading}
        expandable={{ expandedRowRender: getGroupsTable }}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </div>
  )
}
export default SitesTable
