import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import axios from 'axios'
import { query } from '../helpers'
import { useLocalStorage } from './../hooks'

let locked = false

export const DataTable = (props) => {
  const [pageSize, setPageSize] = useLocalStorage('RQ_PAGE_SIZE', 25)
  const [data, setData] = useState([])
  const [params, setParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSize,
      position: ['none', 'bottomCenter'],
      pageSizeOptions: ['25', '50', '100'],
      showSizeChanger: true
    },
    sorter: props.sorter ? props.sorter : {
      field: null,
      order: null
    },
    filters: {},
    customParams: []
  })
  const [loading, setLoading] = useState(false)
  const { filters, columns, size, sorter, customParams } = props

  const getCustomParamsQuery = () => {
    if (params.customParams) {
      return Object.keys(params.customParams)
        .map(function (key) {
          return params.customParams[key] ? `${key}=${params.customParams[key]}` : ''
        })
        .filter((x) => x !== '')
        .join('&')
    }

    return ''
  }

  const fetch = (options = params) => {
    setLoading(true)
    const q = query(options.filters, options.pagination, options.sorter)
    axios
      .get(`/api/${props.endpoint}?${q}&${getCustomParamsQuery()}`)
      .then((res) => {
        const { items, totalCount } = res.data
        setData(items)
        locked = true
        setParams({ ...params, pagination: { ...params.pagination, total: totalCount } })
        if (props.onUpdate) props.onUpdate()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onTableChange = (pagination, filters, sorter) => {
    const newParams = { pagination, filters, sorter }
    locked = false
    setPageSize(pagination.pageSize)
    setParams(newParams)
  }

  useEffect(() => {
    const newParams = { ...params, filters: filters, sorter: sorter ?? params.sorter }
    locked = false
    setParams(newParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    const newParams = { ...params, customParams: customParams }
    locked = false
    setParams(newParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customParams])

  useEffect(() => {
    if (!locked) fetch(params)
  }, [params])

  return (
    <div>
      <Table
        columns={columns}
        size={size || 'small'}
        rowKey={(record) => record.id}
        expandable={{ expandedRowRender: props.expandable }}
        dataSource={data}
        pagination={params.pagination}
        loading={loading}
        onChange={onTableChange}
      />
    </div>
  )
}
