import { Button, Card, Col, Empty, Input, List, Modal, Row, Skeleton, Space, Tooltip, Tree, Typography } from 'antd'
import { DownOutlined, DragOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import React, { useEffect, useState } from 'react'
import { mapNode, reorder, restructureToTree, searchTree } from '../shared/helpers'

import CustomDashboard from '../components/CustomDashboard'
import DailyDashboard from '../components/DailyDashboard'
import MonthlyDashboard from '../components/MonthlyDashboard'
import Page from '../components/Page'
import WeeklyDashboard from '../components/WeeklyDashboard'
import axios from 'axios'
import moment from 'moment'
import useDebounce from '../shared/helpers/debounce'
import { useLocalStorage } from '../shared/hooks'
import { useTranslation } from 'react-i18next'

let originalSensors = []
let autoUpdateInterval = 1000 * 1000 * 5 // 5 minutes

const dashboards = [
  {
    id: 0,
    name: 'dashboards:daily'
  },
  {
    id: 1,
    name: 'dashboards:weekly'
  },
  {
    id: 2,
    name: 'dashboards:monthly'
  },
  {
    id: 3,
    name: 'dashboards:custom'
  }
]

let defaultCharts = [
  'dashboards:temperature',
  'dashboards:extra_temperature',
  'dashboards:humidity',
  'dashboards:battery',
  'dashboards:co2'
]

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 10,
  border: '1px solid rgb(231 231 231)',

  // change background colour if dragging
  background: isDragging ? 'rgb(231 231 231)' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle
})

const Dashboards = (props) => {
  const [dashboard, setDashboard] = useState(0)
  const [sensors, setSensors] = useState([])
  const [loading, setLoading] = useState(false)
  const [lastUpdated, setLastUpdated] = useState(new Date())
  const [selectedSensor, setSelectedSensor] = useState(null)
  const [selectedRules, setSelectedRules] = useState([])
  const [selectedChartSettings, setSelectedChartSettings] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [showSettings, setShowSettings] = useState(false)
  const [charts, setCharts] = useLocalStorage('RQ_CHARTS', defaultCharts)
  const { t } = useTranslation(['global', 'dashboards'])
  const [search, setSearch] = useState('')
  const debounce = useDebounce(search, 200)

  const [autoUpdate, setAutoUpdate] = useState(0)

  const formatDateTime = (value) => {
    return moment(value).format('llll')
  }

  useEffect(() => {
    setLoading(true)

    const expanded = searchTree(debounce, [...sensors])
    setExpandedKeys(expanded)
    setAutoExpandParent(true)
    setLoading(false)
  }, [debounce])

  const onExpand = (eKeys) => {
    setExpandedKeys(eKeys)
    setAutoExpandParent(false)
  }

  const processItems = (data) => {
    return data.map((item) => {
      const index = item.title.toLowerCase().indexOf(search.toLowerCase())
      const beforeStr = item.title.substr(0, index)
      const coloredStr = item.title.substr(index, index + search.length)
      const afterStr = item.title.substr(index + search.length)
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{coloredStr}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        )

      if (item.children) {
        return { ...item, title: title, children: processItems(item.children) }
      }

      return { ...item, title: title }
    })
  }

  const onSelectSensor = (selected, e) => {
    if (selected && selected.length > 0 && selected[0].indexOf('sensor') !== -1) {
      setSelectedSensor({ ...e.selectedNodes[0].dataItem })
    }

    setExpandedKeys(selected)
    setAutoExpandParent(true)
  }

  const handleSettingsSave = () => {
    setShowSettings(false)
  }
  const handleSettingsCancel = () => {
    setShowSettings(false)
  }

  const handleChartReorder = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(charts, result.source.index, result.destination.index)
    setCharts(items)
  }

  useEffect(() => {
    setTimeout(() => {
      setAutoUpdate(autoUpdate + 1)
    }, autoUpdateInterval)
  }, [autoUpdate])

  useEffect(() => {}, [selectedRules])

  useEffect(() => {
    if (selectedSensor) {
      setAutoUpdate(1)

      axios
        .get(`/api/sensors/${selectedSensor.id}/alarmRules`)
        .then((res) => {
          setSelectedRules(res.data)
        })
        .catch((error) => console.error(error))

      axios
        .get(`/api/sensors/${selectedSensor.id}/chartSettings`)
        .then((res) => {
          setSelectedChartSettings(res.data)
        })
        .catch((error) => console.error(error))
    }
  }, [selectedSensor])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/api/sensors`)
      .then((res) => {
        const treeItems = restructureToTree(res.data.items)
        originalSensors = [...treeItems]
        setSensors(treeItems)

        if (res.data.items.length > 0) {
          const firstNode = mapNode(res.data.items[0], 'sensor')
          setSelectedSensor(firstNode.dataItem)
          setExpandedKeys([firstNode.key])
          setAutoExpandParent(true)
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <Modal title={t('global:settings')} visible={showSettings} onOk={handleSettingsSave} onCancel={handleSettingsCancel}>
        <DragDropContext onDragEnd={handleChartReorder}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {charts.map((chart, index) => (
                  <Draggable key={chart} draggableId={chart} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <DragOutlined style={{ marginRight: 10 }} />
                        {t(chart)}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Modal>
      <Row>
        <Col xxl={4} span={6} style={{ position: 'relative' }}>
          <Card size="small" className="dashboard-sensors-filter">
            <div style={{ margin: 15 }}>
              <Input
                placeholder={t('dashboards:search_by_name')}
                onKeyUp={(e) => setSearch(e.target.value)}
                suffix={<SearchOutlined />}
                style={{ width: '100%' }}
              />
            </div>
            <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
              {sensors && (
                <Tree
                  showLine={true}
                  treeData={processItems(sensors)}
                  switcherIcon={<DownOutlined />}
                  expandedKeys={expandedKeys}
                  selectedKeys={expandedKeys}
                  onSelect={onSelectSensor}
                  onExpand={onExpand}
                  autoExpandParent={autoExpandParent}
                />
              )}
              {!sensors || (sensors.length === 0 && <Empty style={{ margin: '50px 0' }} />)}
            </Skeleton>
          </Card>
        </Col>
        <Col xxl={20} span={18} className="dashboards">
          <Page title="" fullWidth>
            <Space direction="vertical" size="large">
              <Row>
                <Col span={18}>
                  <Space className="links">
                    {dashboards.map((d) => (
                      <Button key={d.id} type={dashboard === d.id ? 'primary' : 'ghost'} onClick={() => setDashboard(d.id)}>
                        {t(d.name)}
                      </Button>
                    ))}
                  </Space>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Tooltip title={t('global:settings')}>
                    <Button shape="circle" icon={<SettingOutlined />} onClick={() => setShowSettings(true)} />
                  </Tooltip>
                </Col>
              </Row>
              <Typography.Text style={{ float: 'right', fontSize: '0.8em' }}>
                {t('last_updated', { timestamp: formatDateTime(lastUpdated) })}
              </Typography.Text>
              {dashboard === 0 && (
                <DailyDashboard
                  sensor={selectedSensor}
                  rules={selectedRules}
                  autoUpdate={autoUpdate}
                  charts={selectedChartSettings}
                  onUpdated={() => setLastUpdated(new Date())}
                />
              )}
              {dashboard === 1 && (
                <WeeklyDashboard
                  sensor={selectedSensor}
                  rules={selectedRules}
                  autoUpdate={autoUpdate}
                  charts={selectedChartSettings}
                  onUpdated={() => setLastUpdated(new Date())}
                />
              )}
              {dashboard === 2 && (
                <MonthlyDashboard
                  sensor={selectedSensor}
                  rules={selectedRules}
                  autoUpdate={autoUpdate}
                  charts={selectedChartSettings}
                  onUpdated={() => setLastUpdated(new Date())}
                />
              )}
              {dashboard === 3 && (
                <CustomDashboard
                  sensor={selectedSensor}
                  rules={selectedRules}
                  autoUpdate={autoUpdate}
                  charts={selectedChartSettings}
                  onUpdated={() => setLastUpdated(new Date())}
                />
              )}
            </Space>
          </Page>
        </Col>
      </Row>
    </>
  )
}

export default Dashboards
