import { authenticationService } from '../services'

export const authResponseInterceptor = {
  onSuccess: (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  onFailed: (error) => {
    if ([401, 403].indexOf(error.response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      authenticationService.logout()
    }

    return Promise.reject(error)
  }
}
