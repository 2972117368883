import React, { useState } from 'react'
import { Col, PageHeader, Row } from 'antd'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useTranslation } from 'react-i18next'

const getRoutes = (breadcrumbs, t) => {
  return breadcrumbs.map(({ breadcrumb }) => {
    return { path: breadcrumb.key, breadcrumbName: t(breadcrumb.props.children.toLowerCase()) }
  })
}

const Page = (props) => {
  const breadcrumbs = useBreadcrumbs()
  const { t } = useTranslation('sidebar')
  const [routes] = useState(getRoutes(breadcrumbs, t))

  return (
    <Row justify="center">
      <Col xxl={!!props.fullWidth ? 24 : 18} span={!!props.fullWidth ? 24 : 18}>
        <div className="page">
          <PageHeader
            className="site-page-header"
            extra={props.extra}
            title={t(props.title)}
            breadcrumb={{ routes }}
            subTitle={props.subTitle}
          />
          <div className="page-content">{props.children}</div>
        </div>
      </Col>
    </Row>
  )
}

export default Page
