import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { I18nextProvider } from 'react-i18next'
import { AuthRoute } from './shared/components'
import i18n from './locale/i18n'
import axios from 'axios'
import { ConfigProvider } from 'antd'
import MainLayout from './components/MainLayout'
import { Dashboards, Customers, Sensors, Alarms, Login, Users } from './pages'

import './App.less'
import { authRequestInterceptor, authResponseInterceptor, Role } from './shared/helpers'

import 'moment/locale/nl'
import 'moment/locale/en-gb'

import nlNL from 'antd/lib/locale/nl_NL'
import enGB from 'antd/lib/locale/en_GB'

axios.interceptors.request.use(authRequestInterceptor.onSuccess, authRequestInterceptor.onFailed)
axios.interceptors.response.use(authResponseInterceptor.onSuccess, authResponseInterceptor.onFailed)

const App = (props) => {
  let location = useLocation()
  const [lang, setLang] = useState('nl')

  useEffect(() => {
    i18n.on('languageChanged', () => {
      setLang(i18n.language)
    })
  }, [])

  return (
    <ConfigProvider locale={lang === 'nl' ? nlNL : enGB}>
      <I18nextProvider i18n={i18n}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/">
            <MainLayout>
              <TransitionGroup>
                <CSSTransition
                  unmountOnExit
                  key={location.key}
                  appear
                  classNames="page-anim"
                  timeout={{
                    appear: 300,
                    enter: 300,
                    exit: 200
                  }}
                >
                  <Switch location={location}>
                    <AuthRoute path="/alarms" component={Alarms} />
                    <AuthRoute path="/customers" component={Customers} roles={[Role.SUPER_ADMIN, Role.ADMIN]} />
                    <AuthRoute path="/sensors" component={Sensors} roles={[Role.SUPER_ADMIN, Role.ADMIN]} />
                    <AuthRoute path="/users" component={Users} roles={[Role.SUPER_ADMIN]} />
                    <AuthRoute path="/" component={Dashboards} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </MainLayout>
          </Route>
        </Switch>
      </I18nextProvider>
    </ConfigProvider>
  )
}

export default App
