import React from 'react'
import { Space, Tooltip, Button, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const DataTableActions = ({ record, onDelete, onEdit, canDelete = true, canEdit = true }) => {
  const { t } = useTranslation('global')

  return (
    <div style={{ textAlign: 'right' }}>
      <Space>
        {!!canEdit && (
          <Tooltip title={t('edit_record')}>
            <Button type="text" size="small" shape="circle" icon={<EditOutlined />} onClick={() => onEdit(record)} />
          </Tooltip>
        )}
        {!!canDelete && (
          <Tooltip title={t('delete_record')}>
            <Popconfirm
              title={t('delete_record_confirm')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => onDelete(record)}
            >
              <Button type="text" danger size="small" shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        )}
      </Space>
    </div>
  )
}
