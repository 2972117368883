import { AlarmRuleKey, createChartAnnotations } from '../shared/helpers'
import { Col, DatePicker, Descriptions, Row, Spin, Typography } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { ChartType } from '../shared/helpers'
import { Area } from '@ant-design/charts'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const DailyDashboard = (props) => {
  const [sensor, setSensor] = useState(null)
  const [rules, setRules] = useState([])
  const { t } = useTranslation(['global', 'dashboards'])
  const [loading, setLoading] = useState(false)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [temperatureData, setTemperatureData] = useState([])
  const [extraTemperatureData, setExtraTemperatureData] = useState([])
  const [batteryData, setBatteryData] = useState([])
  const [humidityData, setHumidityData] = useState([])
  const [co2Data, setCo2Data] = useState([])

  const fetchData = () => {
    if (!sensor) return

    setLoading(true)

    axios
      .get(
        `/api/data/${sensor.id}/date?year=${currentDate.getUTCFullYear()}&month=${
          currentDate.getUTCMonth() + 1
        }&day=${currentDate.getUTCDate()}`
      )
      .then((res) => {
        processData(res.data)
        props.onUpdated()
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }

  const processData = (data) => {
    const temp = []
    const extraTemp = []
    const bat = []
    const hum = []
    const co2 = []

    data.forEach((item) => {
      const dateString = moment(item.timestamp).format('ddd LT')

      temp.push({ date: dateString, value: item.temperature })
      extraTemp.push({ date: dateString, value: item.extraTemperature })
      bat.push({ date: dateString, value: item.battery })
      hum.push({ date: dateString, value: item.humidity })
      co2.push({ date: dateString, value: item.co2 })
    })

    setTemperatureData(temp)
    setExtraTemperatureData(extraTemp)
    setBatteryData(bat)
    setHumidityData(hum)
    setCo2Data(co2)
  }

  const onDateChange = (date, dateString) => {
    setCurrentDate(new Date(date))
  }

  const getChartAnnotations = (key) => {
    const kIndex = rules.findIndex((x) => x.key === key)
    if (kIndex !== -1) {
      const kRule = rules[kIndex]
      return createChartAnnotations(kRule.minValue, kRule.maxValue)
    }

    return []
  }

  useEffect(() => {
    setSensor(props.sensor)
  }, [props.sensor])

  useEffect(() => {
    setRules(props.rules)
  }, [props.rules])

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensor, currentDate, props.autoUpdate])

  return (
    <>
      <Spin spinning={loading}>
        {sensor && (
          <div>
            <Typography.Title level={2}>{t('dashboards:daily_dashboard')}</Typography.Title>
            <Descriptions column={6} labelStyle={{ color: 'rgb(195 195 195)' }}>
              <Descriptions.Item label={t('dashboards:sensor')}>{sensor.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:location')}>{sensor.location?.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:site')}>{sensor.site?.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:room')}>{sensor.room?.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:date')}>
                <DatePicker size="small" onChange={onDateChange} defaultValue={moment(currentDate)} />
              </Descriptions.Item>
            </Descriptions>

            <Row>
              {props.charts &&
                props.charts
                  .filter((x) => x.isShown)
                  .map((chart) => (
                    <Fragment key={chart}>
                      {chart.chartType === ChartType.TEMPERATURE && (
                        <Col span={24}>
                          <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                            {chart.title || t('dashboards:temperature')}
                          </Typography.Title>
                          <Area
                            data={temperatureData}
                            xField="date"
                            yField="value"
                            xAxis={{ tickCount: 5 }}
                            areaStyle={{ fillOpacity: 0.5, fill: '#ffd8bf' }}
                            line={{ color: '#ff9c6e' }}
                            annotations={getChartAnnotations(AlarmRuleKey.TEMPERATURE)}
                          />
                        </Col>
                      )}
                      {chart.chartType === ChartType.EXTRA_TEMPERATURE && (
                        <Col span={24}>
                          <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                            {chart.title || t('dashboards:extra_temperature')}
                          </Typography.Title>
                          <Area
                            data={extraTemperatureData}
                            xField="date"
                            yField="value"
                            xAxis={{ tickCount: 5 }}
                            areaStyle={{ fillOpacity: 0.5, fill: '#ffd8bf' }}
                            line={{ color: '#ff9c6e' }}
                            annotations={getChartAnnotations(AlarmRuleKey.EXTRA_TEMPERATURE)}
                          />
                        </Col>
                      )}
                      {chart.chartType === ChartType.BATTERY && (
                        <Col span={24}>
                          <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                            {chart.title || t('dashboards:battery')}
                          </Typography.Title>
                          <Area
                            data={batteryData}
                            xField="date"
                            yField="value"
                            xAxis={{ tickCount: 5 }}
                            areaStyle={{ fillOpacity: 0.5, fill: '#ffd6e7' }}
                            line={{ color: '#ff85c0' }}
                            annotations={getChartAnnotations(AlarmRuleKey.BATTERY)}
                          />
                        </Col>
                      )}

                      {chart.chartType === ChartType.HUMIDITY && (
                        <Col span={24}>
                          <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                            {chart.title || t('dashboards:humidity')}
                          </Typography.Title>
                          <Area
                            data={humidityData}
                            xField="date"
                            yField="value"
                            xAxis={{ tickCount: 5 }}
                            areaStyle={{ fillOpacity: 0.5, fill: '#d9f7be' }}
                            line={{ color: '#95de64' }}
                            annotations={getChartAnnotations(AlarmRuleKey.HUMIDITY)}
                          />
                        </Col>
                      )}

                      {chart.chartType === ChartType.CO2 && (
                        <Col span={24}>
                          <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                            {chart.title || t('dashboards:co2')}
                          </Typography.Title>
                          <Area
                            data={co2Data}
                            xField="date"
                            yField="value"
                            xAxis={{ tickCount: 5 }}
                            areaStyle={{ fillOpacity: 0.5, fill: '#bae7ff' }}
                            line={{ color: '#69c0ff' }}
                            annotations={getChartAnnotations(AlarmRuleKey.CO2)}
                          />
                        </Col>
                      )}
                    </Fragment>
                  ))}
            </Row>
          </div>
        )}
      </Spin>
    </>
  )
}
export default DailyDashboard
