import { Menu } from 'antd'
import {
  BarChartOutlined,
  NotificationOutlined,
  QuestionOutlined,
  TeamOutlined,
  ClusterOutlined,
  AppstoreOutlined,
  ToolOutlined
} from '@ant-design/icons'
import logo from '../shared/images/hydroscope_logo.png'
import developerLogo from '../shared/images/technoberg_logo.png'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { authenticationService } from '../shared/services'
import { Role } from '../shared/helpers'

const SideBar = (props) => {
  let location = useLocation()
  const { t } = useTranslation('sidebar')

  return (
    <div>
      <img src={logo} className="app-logo" alt="ReportingQ" />

      <Menu mode="inline" theme="dark" className="app-sider" defaultSelectedKeys={[location.pathname]}>
        <Menu.Item key="/dashboards" icon={<BarChartOutlined />}>
          <NavLink to="/dashboards">{t('dashboards')}</NavLink>
        </Menu.Item>
        <Menu.Item key="/alarms" icon={<NotificationOutlined />}>
          <NavLink to="/alarms">{t('alarms')}</NavLink>
        </Menu.Item>
        {authenticationService.hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
          <Menu.ItemGroup key="g2" title={t('management')}>
            <Menu.Item key="/customers" icon={<AppstoreOutlined />}>
              <NavLink to="/customers">{t('customers')}</NavLink>
            </Menu.Item>
            {authenticationService.hasRoles([Role.SUPER_ADMIN]) && (
              <Menu.Item key="/users" icon={<TeamOutlined />}>
                <NavLink to="/users">{t('users')}</NavLink>
              </Menu.Item>
            )}
            {authenticationService.hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
              <Menu.Item key="/sensors" icon={<ClusterOutlined />}>
                <NavLink to="/sensors">{t('sensors')}</NavLink>
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        )}
        {/* <Menu.ItemGroup key="g3" title={t('configuration')}>
          <Menu.Item key="6" icon={<ToolOutlined />}>
            <NavLink to="/settings">{t('settings')}</NavLink>
          </Menu.Item>
          <Menu.Item key="/help" icon={<QuestionOutlined />}>
            <NavLink to="/help">{t('help')}</NavLink>
          </Menu.Item>
        </Menu.ItemGroup> */}
      </Menu>

      <img src={developerLogo} className="dev-logo" alt="Technoberg IT Services" />
    </div>
  )
}
export default SideBar
