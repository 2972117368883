export const sortByName = (a, b) => {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export const createChartAnnotations = (min, max) => {
  return [
    {
      type: 'line',
      start: ['min', min],
      end: ['max', min],
      style: {
        stroke: '#fa541c',
        lineDash: [3, 3]
      }
    },
    {
      type: 'line',
      start: ['min', max],
      end: ['max', max],
      style: {
        stroke: '#f5222d',
        lineDash: [3, 3]
      }
    }
  ]
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
