import React, { useEffect, useState } from 'react'
import { Button, Select, Switch, Table, Popconfirm, InputNumber, Spin, Popover, Tooltip, Input, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { ChartType } from '../shared/helpers'

const ChartSettingsDetails = (props) => {
  const [chartSettings, setChartSettings] = useState([])
  const { t } = useTranslation(['global', 'charts'])

  const handleOnChange = (index, record) => {
    const newChartSettings = [...chartSettings]
    newChartSettings[index] = record
    setChartSettings(newChartSettings)
  }

  const getChartTypeLocale = (type) => {
    switch (type) {
      case ChartType.TEMPERATURE:
        return t('charts:temperature')
      case ChartType.EXTRA_TEMPERATURE:
        return t('charts:extra_temperature')
      case ChartType.BATTERY:
        return t('charts:battery')
      case ChartType.HUMIDITY:
        return t('charts:humidity')
      case ChartType.CO2:
        return t('charts:co2')
    }
  }
  const columns = [
    {
      title: t('charts:type'),
      dataIndex: 'chartType',
      key: 'chartType',
      render: (_, record, index) => <td>{getChartTypeLocale(record.chartType)}</td>
    },
    {
      title: t('charts:title'),
      dataIndex: 'title',
      key: 'title',
      render: (_, record, index) => (
        <Input
          defaultValue={record.title}
          size="small"
          onChange={(e) => handleOnChange(index, { ...record, title: e.target.value.trim() })}
        />
      )
    },
    {
      title: t('charts:show_chart'),
      dataIndex: 'isShown',
      key: 'isShown',
      render: (_, record, index) => (
        <Switch defaultChecked={record.isShown} size="small" onChange={(v) => handleOnChange(index, { ...record, isShown: v })} />
      )
    }
  ]

  useEffect(() => {
    setChartSettings([...(props.chartSettings || [])])
  }, [props.chartSettings])

  useEffect(() => {
    if (props.onChange) props.onChange(chartSettings)
  }, [chartSettings])

  return (
    <div>
      <Table size="small" title={() => t('charts:chart_settings')} dataSource={chartSettings} columns={columns} />
    </div>
  )
}

export default ChartSettingsDetails
