export const queryOperator = {
  contains: '@=',
  equals: '==',
  gte: '>=',
  lte: '<='
}

const buildFilterQuery = (key, filter) => {
  return `${key}${filter.operator}${filter.value}`
}

export const query = (filters, pagination, sorter) => {
  let queryParts = []

  // filters
  const filtersKeys = filters ? Object.keys(filters) : []
  if (filtersKeys.length > 0) {
    let filterQ = 'filters='
    filterQ += filtersKeys.map((key) => {
      return buildFilterQuery(key, filters[key])
    })
    queryParts.push(filterQ)
  }

  // sorting
  if (sorter && sorter.field) {
    let order = sorter.order === 'descend' ? '-' : ''
    queryParts.push(`sorts=${order}${sorter.field}`)
  }

  //pagination
  if (pagination) {
    queryParts.push(`page=${pagination.current}`)
    queryParts.push(`pageSize=${pagination.pageSize}`)
  }

  return queryParts.join('&')
}
