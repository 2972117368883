import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import useDebounce from '../helpers/debounce'
import axios from 'axios'

const { Option } = Select

export const RemoteSelect = (props) => {
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const debounce = useDebounce(search, props.debounce || 200)

  const onChange = (id) => {
    const index = data.findIndex((x) => x.id === +id)
    if (index === -1) return
    if (props.onChange) props.onChange(data[index])
  }

  useEffect(() => {
    setLoading(true)

    const query = `/api/${props.endpoint}?filters=${props.searchProperty}@=${debounce}&pageSize=${props.maxResults || 10}`
    axios
      .get(query)
      .then((res) => {
        setData([...res.data.items])
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [debounce, props.endpoint, props.searchProperty, props.maxResults])

  useEffect(() => {
    if (props.fieldValue) {
      setLoading(true)
      const query = `/api/${props.endpoint}/${props.fieldValue}`
      axios
        .get(query)
        .then((res) => {
          const newData = [res.data]
          console.log('🚀 ~ file: RemoteSelect.js ~ line 45 ~ .then ~ newData', newData[0])
          setData(newData)
          props.onChange(newData[0])
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fieldValue])

  return (
    <Select
      loading={loading}
      allowClear
      value={props.fieldValue}
      filterOption={(input, option) => true}
      showSearch
      disabled={props.disabled}
      onSearch={(value) => setSearch(value)}
      onChange={onChange}
    >
      {data &&
        data.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
    </Select>
  )
}
