import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Button, Space, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

const ComponentDetails = (props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation(['global', 'components'])
  const [component, setComponent] = useState({})
  const [loading, setLoading] = useState(false)

  const onSave = (values) => {
    setLoading(true)

    const data = Object.assign(component, values)
    const request =
      !data.id || data.id === 0 ? axios.post('/api/components', data) : axios.put(`/api/components/${component.id}`, data)

    request
      .then(() => {
        props.onSuccessfullySaved()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSaveFailed = (error) => {
    console.log('🚀 ~ file: CustomerDetails.js ~ line 12 ~ CustomerDetails ~ error', error)
  }

  const onReset = () => {
    form.resetFields()
    setComponent({})
    if (props.onCancel) props.onCancel()
  }

  useEffect(() => {
    const newComponent = { ...props.component }
    setComponent(newComponent)
    form.setFieldsValue(newComponent)
  }, [props.component, form])

  return (
    <div>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={component} onFinish={onSave} onFinishFailed={onSaveFailed}>
          <Form.Item label={t('components:name')} name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space align="end">
              <Button type="primary" htmlType="submit">
                {t('save')}
              </Button>
              <Button htmlType="button" onClick={onReset}>
                {t('cancel')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  )
}
export default ComponentDetails
