import React, { useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { queryOperator, Role } from '../shared/helpers'
import CustomerDetails from '../components/CustomerDetails'
import Page from '../components/Page'
import { DataTable, DataTableActions } from '../shared/components'
import axios from 'axios'
import LocationTable from '../components/LocationsTable'
import { authenticationService } from '../shared/services'

const Customers = (props) => {
  const { t } = useTranslation(['global', 'customers'])
  const [filters, setFilters] = useState({})
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const refresh = () => {
    setFilters({ ...filters })
    setDetails(null)
  }

  const onDelete = (item) => {
    setLoading(true)
    axios
      .delete(`/api/customers/${item.id}`)
      .then(() => {
        refresh()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const columns = [
    {
      title: t('customers:name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: '',
      key: 'actions',
      width: '15%',
      render: (text, record) => (
        <DataTableActions
          record={record}
          onEdit={setDetails}
          onDelete={onDelete}
          canDelete={authenticationService.hasRoles([Role.SUPER_ADMIN])}
        />
      )
    }
  ]

  const onFilterFinish = (values) => {
    let newFilters = {}

    if (values.name) {
      newFilters.name = { value: values.name, operator: queryOperator.contains }
    }

    if (values.location) {
      newFilters.byLocation = { value: values.location, operator: queryOperator.contains }
    }

    if (values.site) {
      newFilters.bySite = { value: values.site, operator: queryOperator.contains }
    }

    if (values.group) {
      newFilters.byGroup = { value: values.group, operator: queryOperator.contains }
    }

    if (values.room) {
      newFilters.byRoom = { value: values.room, operator: queryOperator.contains }
    }

    if (values.component) {
      newFilters.byComponent = { value: values.component, operator: queryOperator.contains }
    }

    setFilters(newFilters)
  }

  const getLocationTable = (row) => {
    return <LocationTable locations={row.locations} customerId={row.id} onRefresh={refresh} />
  }

  const CreateCustomerButton = authenticationService.hasRoles([Role.SUPER_ADMIN]) ? (
    <Button key="1" onClick={() => setDetails({})}>
      {t('customers:new_customer')}
    </Button>
  ) : null

  return (
    <Page title="customers" extra={[CreateCustomerButton]}>
      <Modal destroyOnClose footer={null} centered title={details?.name || t('customers:new_customer')} visible={!!details}>
        <CustomerDetails customer={details} onSuccessfullySaved={refresh} onCancel={() => setDetails(null)} />
      </Modal>

      <Row gutter={30}>
        <Col span={18} xxl={20}>
          <Spin spinning={loading}>
            <DataTable columns={columns} expandable={getLocationTable} filters={filters} endpoint="customers" />
          </Spin>
        </Col>
        <Col span={6} xxl={4}>
          <Form form={form} layout="vertical" initialValues={filters} onFinish={onFilterFinish}>
            <Form.Item label="Name" name="name">
              <Input allowClear placeholder={t('customers:search_by_name')} />
            </Form.Item>
            <Form.Item label="Location" name="location">
              <Input allowClear placeholder={t('customers:search_by_location')} />
            </Form.Item>
            <Form.Item label="Site" name="site">
              <Input allowClear placeholder={t('customers:search_by_site')} />
            </Form.Item>
            <Form.Item label="Group" name="group">
              <Input allowClear placeholder={t('customers:search_by_group')} />
            </Form.Item>
            <Form.Item label="Room" name="room">
              <Input allowClear placeholder={t('customers:search_by_room')} />
            </Form.Item>
            <Form.Item label="Component" name="component">
              <Input allowClear placeholder={t('customers:search_by_component')} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('refresh')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}

export default Customers
